<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title && title !== '' ?  title : 'Confirm' }}</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <div class="my-3">{{ message && message !== '' ? message : 'Are you sure?' }}</div>
          <div v-if="forceConfirmation" class="my-3 p-3 border rounded bg-light">
            <div class="mb-2">
              Please type <strong>DELETE</strong> <em>(all caps)</em>
              in the text field below to confirm your action:
            </div>
            <input
              v-model.trim="confirmationText"
              type="text"
              class="form-control"
              placeholder="Type here..."
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="close"
            type="button"
            class="btn btn-primary"
          >{{ noLabel }}</button>
          <button
            @click="confirm"
            :disabled="isButtonDisabled"
            type="button"
            class="btn btn-danger"
          >{{ yesLabel }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    yesLabel: {
      type: String,
      default: 'OK',
    },
    noLabel: {
      type: String,
      default: 'Cancel',
    },
    forceConfirmation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isButtonDisabled() {
      const { forceConfirmation, confirmationText, confirmationCode } = this;
      if (!forceConfirmation) return false;
      return !(confirmationText === confirmationCode);
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
  data() {
    return {
      confirmationCode: 'DELETE',
      confirmationText: '',
    };
  },
};
</script>
